import { useState } from 'react';
import { cleanFileName } from '../../utils/cleanFileName';

// Define types for the hook return values
interface UseTTSReturn {
  fetchTTS: (text: string, title: string, language: string, voice: string, format: string) => Promise<void>;
  audioUrl: string;
  audioDuration: number | null;
  loading: boolean;
  error: string | null;
  audioFile: File | null;
}

const useTTS = (): UseTTSReturn => {
  const [audioUrl, setAudioUrl] = useState<string>('');
  const [audioDuration, setAudioDuration] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [audioFile, setAudioFile] = useState<File | null>(null);

  const fetchTTS = async (text: string, title: string, language: string, voice: string, format: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch('https://northeurope.tts.speech.microsoft.com/cognitiveservices/v1', {
        method: 'POST',
        headers: {
          Authorization:
            'Bearer eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJyZWdpb24iOiJub3J0aGV1cm9wZSIsInN1YnNjcmlwdGlvbi1pZCI6IjdlYjZjZDMzNzZhMjQwYjI5NTZhZDBlNDU3OWZmMDBmIiwicHJvZHVjdC1pZCI6IlNwZWVjaFNlcnZpY2VzLkYwIiwiY29nbml0aXZlLXNlcnZpY2VzLWVuZHBvaW50IjoiaHR0cHM6Ly9hcGkuY29nbml0aXZlLm1pY3Jvc29mdC5jb20vaW50ZXJuYWwvdjEuMC8iLCJhenVyZS1yZXNvdXJjZS1pZCI6Ii9zdWJzY3JpcHRpb25zLzk5NjY2OGVjLTEzY2YtNDNhNC1iMGRjLTdlNmNkYTc1MTFiOC9yZXNvdXJjZUdyb3Vwcy9Ob3Jza0ludGVyYWt0aXYvcHJvdmlkZXJzL01pY3Jvc29mdC5Db2duaXRpdmVTZXJ2aWNlcy9hY2NvdW50cy9uaWFzLVRleHRUb1NwZWVjaCIsInNjb3BlIjoic3BlZWNoc2VydmljZXMiLCJhdWQiOiJ1cm46bXMuc3BlZWNoc2VydmljZXMubm9ydGhldXJvcGUiLCJleHAiOjE2NjMxNTkxMDIsImlzcyI6InVybjptcy5jb2duaXRpdmVzZXJ2aWNlcyJ9.eGJk8pNSWo4rqWbUUw4S0-p4ZXlYUitQrF77hNcVr1k',
          'Content-Type': 'application/ssml+xml',
          'Ocp-Apim-Subscription-Key': '1aa26fcc128b48e2b49204d6a15a1ec1',
          'X-Microsoft-OutputFormat': format,
          'User-Agent': 'nias',
        },
        body: `
            <speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xml:lang="${language}">
              <voice name="${voice}">${text}</voice>
            </speak>`,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const audioData = await response.arrayBuffer();
      const audioBlob = new Blob([audioData], { type: 'audio/mp3' });

      // Create a timestamp for the file name in the format voice_ddmmyy_hhmmss
      const now = new Date();
      const timestamp = `${now.getFullYear().toString().slice(-2)}${now.getDate().toString().padStart(2, '0')}${(now.getMonth() + 1).toString().padStart(2, '0')}_${now
        .getHours()
        .toString()
        .padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}${now.getSeconds().toString().padStart(2, '0')}`;
      const fileName = `${cleanFileName(title)}_voice_${language}.mp3`;

      // Create a File object with the timestamped name
      const file = new File([audioBlob], fileName, { type: 'audio/mp3' });

      // Create a temporary audio element to get the duration
      const audioUrl = URL.createObjectURL(audioBlob);
      const audioElement = new Audio(audioUrl);
      audioElement.addEventListener('loadedmetadata', () => {
        setAudioDuration(audioElement.duration); // Get the duration in seconds
      });

      setAudioUrl(audioUrl);
      setAudioFile(file);
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return { fetchTTS, audioUrl, audioDuration, loading, error, audioFile };
};

export default useTTS;
