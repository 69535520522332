import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import LanguageSelector from './components/LanguageSelector';
import AudioPlayer from './components/AudioPlayer';
import useTTS from './useTTS';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import { useTheme } from '@mui/material/styles';
import { useStore } from '../../store/store';

interface IAIVoiceProps {
  onSelect?: (aSrc: string, aFileName?: string) => void;
}

export default observer(function AiVoice({ onSelect }: IAIVoiceProps) {
  const { mediaLibraryStore, pageStore, courseStore } = useStore();
  const { uploadFileToFolder } = mediaLibraryStore;
  const { coursePage } = pageStore;
  const { pageProperties } = courseStore;
  const [language, setLanguage] = useState('en-GB');
  const [textInput, setTextInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [voice, setVoice] = useState('');
  const [volume, setVolume] = useState(0);
  const theme = useTheme();
  const { fetchTTS, audioFile, audioUrl, audioDuration, loading, error } = useTTS();

  const handleGenerateVoice = () => {
    if (textInput.length < 1) return;
    const title = coursePage?.title ?? pageProperties?.descriptions?.items?.title?.option?.value ?? 'noName';
    fetchTTS(textInput, title, language, voice, 'audio-48khz-192kbitrate-mono-mp3');
  };

  const handleUploadVoice = () => {
    if (audioFile === null) return;
    setIsLoading(true);
    uploadFileToFolder(audioFile, 'AIVoice').then((test) => {
      if (!test) return;
      const keys = Object.keys(test);

      if (keys.length < 1 || test[keys[0]].status === false) {
        //SnackbarUtils.warning('Upload failed. Try again.');
        return;
      }
      if (onSelect) {
        onSelect(test[keys[0]].url, test[keys[0]].mediaTitle);
      }
    });
  };

  interface IVoicesByLanguage {
    [key: string]: string[];
  }
  const voicesByLanguage: IVoicesByLanguage = {
    'nb-NO': ['nb-NO-FinnNeural', 'nb-NO-PernilleNeural'],
    'en-GB': ['en-GB-SoniaNeural', 'en-GB-RyanNeural', 'en-GB-LibbyNeural', 'en-GB-AlfieNeural'],
    'en-US': ['en-US-EmmaNeural', 'en-US-AvaNeural', 'en-US-AndrewNeural', 'en-US-BrianNeural'],
    'de-DE': ['de-DE-KatjaNeural', 'de-DE-ConradNeural', 'de-DE-AmalaNeural', 'de-DE-BerndNeural'],
    'es-ES': ['es-ES-ElviraNeural', 'es-ES-AlvaroNeural', 'es-ES-AbrilNeural', 'es-ES-ArnauNeural'],
    'fr-FR': ['fr-FR-DeniseNeural', 'fr-FR-HenriNeural', 'fr-FR-AlainNeural', 'fr-FR-BrigitteNeural'],
    'sv-SE': ['sv-SE-SofieNeural', 'sv-SE-MattiasNeural'],
    'fi-FI': ['fi-FI-SelmaNeural', 'fi-FI-HarriNeural'],
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    const voice = voicesByLanguage[language][0];
    setVoice(voice);
  }, [language]);

  const useStyles = createUseStyles(() => ({
    loader: {
      width: 'fit-content',
      fontSize: '18px',
      clipPath: 'inset(0 1.5ch 0 0)',
      animation: '$loadingAnimation 2s steps(4) infinite',
      '&:before': {
        content: '"Loading..."',
      },
    },
    '@keyframes loadingAnimation': {
      to: {
        clipPath: 'inset(0 -0.5ch 0 0)',
      },
    },
  }));
  const classes = useStyles();

  return (
    <>
      <div
        style={{
          backgroundColor: theme.palette.bgTwo.main,
          padding: theme.spacing(2),
          gap: theme.spacing(2),
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
        }}
      >
        <Typography variant='body1' fontWeight={'500'}>
          Text for AI Voice Generator
        </Typography>
        <div style={{ display: 'flex', flexGrow: 1, gap: theme.spacing(2) }}>
          <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexGrow: 1, position: 'relative' }}>
              <textarea
                style={{ backgroundColor: theme.palette.bgZero.main, padding: theme.spacing(2), flexGrow: 1, color: theme.palette.text.primary, resize: 'none', border: 0, outline: 0, fontSize: 14 }}
                placeholder='Write the text you wish to have generated into AI voice'
                value={textInput}
                onChange={(e: any) => setTextInput(e.target.value)}
              />
              {isLoading && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    backgroundColor: 'rgba(0,0,0,0.6)',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div className={classes.loader}></div>
                </div>
              )}
            </div>
            <AudioPlayer audioSrc={audioUrl} handleSetVolume={setVolume} duration={audioDuration} />
          </div>
          <div style={{ flexBasis: 350, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <LanguageSelector language={language} setLanguage={setLanguage} voice={voice} setVoice={setVoice} />
            <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button color='primary' variant='contained' style={{ alignSelf: 'baseline', minWidth: 170 }} onClick={handleGenerateVoice} disabled={isLoading}>
                  GENERATE AI VOICE
                </Button>
                <Button variant='newSecondary' style={{ alignSelf: 'baseline', minWidth: 170, fontWeight: 700 }} disabled={audioUrl.length < 1 || isLoading} onClick={handleUploadVoice}>
                  APPLY TO PAGE
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
